.VideoPlayerView {
	height: 100vh;

	.youtubeContainer {
		position: relative;

		.centerObjects {
			position: absolute;
			top: 50vh;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.controls {
		position: absolute;
		bottom: 0px;
		width: 100%;

		.time {
			width: 100%;
			text-align: center;
			font-size: 14px;
			margin-bottom: 5px;
		}

		.scrubber {
			width: 100%;
			background-color: rgba(0, 0, 0, 0.25);

			.scrubberElement {
				width: 200px;
				height: 10px;
				background: rgba(255, 255, 255, 1);
			}
		}
	}

	.introScreen {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.description {
			width: 900px;
			text-align: center;

			.header {
				text-align: center;
				font-size: 40px;
				line-height: 1.1;
				margin-bottom: 20px;
			}

			.textContent {
				//display: flex;
				text-align: center;
				width: 420px;

				margin: 0 auto;
				.scenario {
					width: 420px;
					margin-bottom: 30px;
				}

				.howTo {
					width: 420px;
					margin-bottom: 0px;
				}

				.smallHeader {
					font-size: 26px;
					margin-bottom: -5px;
				}

				.body {
					font-size: 20px;
					opacity: 0.5;
					line-height: 1.63;
				}

				margin-top: 37px;
			}
		}

		.TouchCardContainer {
			.TouchCard {
				margin-top: 80px;
				margin-left: 150px;
				width: 538px;
				height: 130px;

				.background {
					width: 538px;
					height: 130px;
					border-radius: 5px;

					.line {
						top: calc(130px - 13px);
					}
				}

				.cardContent {
					.startDemo {
						margin-top: 30px;
						margin-left: 40px;
						font-size: 24px;
					}

					.imageContainer {
						position: absolute;
						top: -20px;
						right: -10px;

						svg {
							transform: scale(0.2);
						}
					}
				}
			}
		}
	}

	.demoScreen {
		visibility: hidden;
		display: none;

		.draggerContent {
			width: 200vw;
		}

		.TouchCardContainer {
			z-index: 10;
			position: absolute;
			left: calc(50vw + 350px);
			top: calc(50vh - 185px);

			.TouchCard {
				width: 200px;
				height: 360px;

				.playButton {
					display: none;
					margin-top: 120px;
					margin-left: 55px;
				}

				.pauseButton {
					margin-top: 120px;
					margin-left: 55px;
				}

				.line {
					top: calc(360px - 13px);
				}
			}
		}
	}
}
