.ElevatorView {
	position: fixed;
	height: 100vh;

	.introScreen {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.description {
			width: 900px;
			text-align: center;

			.header {
				text-align: center;
				font-size: 40px;
				line-height: 1.1;
				margin-bottom: 20px;
			}

			.textContent {
				//display: flex;
				text-align: center;
				width: 420px;

				margin: 0 auto;
				.scenario {
					width: 420px;
					margin-bottom: 30px;
				}

				.howTo {
					width: 420px;
					margin-bottom: 0px;
				}

				.smallHeader {
					font-size: 26px;
					margin-bottom: -5px;
				}

				.body {
					font-size: 20px;
					opacity: 0.5;
					line-height: 1.63;
				}

				margin-top: 37px;
			}
		}

		.TouchCardContainer {
			.TouchCard {
				margin-top: 80px;
				margin-left: 150px;
				width: 538px;
				height: 130px;

				.background {
					width: 538px;
					height: 130px;
					border-radius: 5px;

					.line {
						top: calc(130px - 13px);
					}
				}

				.cardContent {
					.startDemo {
						margin-top: 30px;
						margin-left: 40px;
						font-size: 24px;
					}

					.imageContainer {
						position: absolute;
						top: -20px;
						right: -10px;

						svg {
							transform: scale(0.2);
						}
					}
				}
			}
		}
	}

	.demoScreen {
		display: none;
		visibility: hidden;
		display: flex;
		justify-content: space-between;
		top: calc(75px);

		.numberContainer {
			display: flex;
			flex-wrap: wrap;
			position: absolute;

			right: 20px;
			max-width: 50vw;
			margin-top: 15vh;

			.TouchCardContainer {
				.TouchCard {
					//	width: 160px;
					//	height: 160px;
					width: 20vw;
					height: 20vh;
					margin-top: 20px;
					margin-right: 20px;

					.line {
						top: calc(20vh - 13px);
					}

					.cardContent {
						font-size: 90px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						text-align: center;
					}

					.background {
						//	border-radius: 50%;

						.line {
							//	top: 0%;
							//	height: 100%;
							//	border-radius: 50%;
						}
					}
				}
			}
		}

		.currentFloor {
			position: absolute;
			margin-left: 10vw;
			left: 0px;
			top: calc(50vh - 95px);
			width: 50vw;
			display: flex;

			.arrow {
				margin-right: 30px;
			}

			.floorContainer {
				width: 160px;
				height: 205px;
				overflow: hidden;
				text-align: center;

				.floors {
					margin-top: 0px;
					font-size: 260px;
					font-weight: bold;
					line-height: 0.85;
				}
			}
		}
	}

	.endScreen {
		pointer-events: none;
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.headline {
			font-size: 50px;
		}
	}
}
